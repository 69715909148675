import type { ExtendedDocumentInitialProps } from '@telus/web-app-core'
import NextDocument from 'next/document'
import GeContext from './components/Context'
import getGeCookies from './lib/getGeCookies'
import GlobalElementsHead from './components/GlobalElementsHead'
import GlobalElementsScript from './components/GlobalElementsScript'
import { geMockData } from './lib/constants'
import { fetchGeDataWithCircuitBreaker } from './lib/fetchGeDataWithCircuitBreaker'
import getServerSideLocale from './lib/getServerSideLocale'
import type { DocumentContext } from 'next/document'
import type { AppType, AppProps } from 'next/app'
import type { GlobalElementsData, GlobalElementsApiOptions } from './lib/types'

type NextComponentEnhancer = NonNullable<Parameters<DocumentContext['renderPage']>[0]>
type ComponentEnhancer = NonNullable<Extract<NextComponentEnhancer, { enhanceApp?: any }>>
type AppEnhancer = NonNullable<ComponentEnhancer['enhanceApp']>

function mergeEnhancers(original: AppEnhancer, current: AppEnhancer) {
  return (Element: AppType) => {
    return current(original(Element))
  }
}

export const GE_API_OPTS: GlobalElementsApiOptions = {
  disabled: false,
  defaultLang: 'en',
  defaultRegion: 'bc',
  header: 'personal',
  footer: 'personal',
  cookies: '',
  routeUrl: '',
  hideFooter: false,
  hideNotifBanner: false,
  withoutFonts: '',
  hideCart: false
}

interface GEComponentopts {
  globalElements: GlobalElementsData
  scripts: JSX.Element[]
}

type CustomDocument = typeof NextDocument<GEComponentopts> & {
  getGeApiOpts?: (ctx: DocumentContext) => GlobalElementsApiOptions
}

export function withGlobalElementsDoc(
  BaseDocument: CustomDocument = NextDocument<GEComponentopts>
) {
  return class GlobalElementsDocument extends BaseDocument {
    static getGeApiOpts = () => GE_API_OPTS

    static GE_USE_MOCK_DATA = process.env.GE_USE_MOCK_DATA
    static GE_MOCK_DATA = geMockData

    static async getInitialProps(ctx: DocumentContext) {
      const isServer = !!ctx.req
      const getGeApiOpts = BaseDocument.getGeApiOpts ?? GlobalElementsDocument.getGeApiOpts
      const geApiOpts = { ...GE_API_OPTS, ...getGeApiOpts(ctx) }

      if (geApiOpts?.disabled) {
        const initialProps = await BaseDocument.getInitialProps(ctx)
        return {
          ...initialProps,
          globalElements: false,
        }
      }

      const { defaultLang, defaultRegion } = geApiOpts

      // If initial load has cookies, use them to generate options for GE API
      // otherwise use default options.
      if (isServer) {
        const cookies: Record<string, string> = (ctx.req as any).cookies || {}

        const { lang, prov } = getServerSideLocale(ctx)

        geApiOpts.lang = lang?.toLowerCase() ?? cookies.lang?.toLowerCase() ?? defaultLang
        geApiOpts.prov = prov?.toLowerCase() ?? cookies.prov?.toLowerCase() ?? defaultRegion

        const geCookies = getGeCookies(cookies)

        geApiOpts.cookies = Object.keys(geCookies).length ? geCookies : ''
      } else {
        geApiOpts.lang = defaultLang
        geApiOpts.prov = defaultRegion
        geApiOpts.cookies = ''
      }

      if (GlobalElementsDocument.GE_USE_MOCK_DATA) {
        geApiOpts.mockData = GlobalElementsDocument.GE_MOCK_DATA
      }

      const geApiData = (await fetchGeDataWithCircuitBreaker(geApiOpts)) as any
      const isFooterAvailable = !geApiOpts.hideFooter
      const globalElements: GlobalElementsData = {
        ctx: {
          header: {
            content: geApiData.header.content,
            html: geApiData.header.html
          },
          ...(isFooterAvailable
            ? {
                footer: {
                  content: geApiData.footer.content,
                  html: geApiData.footer.html
                }
              }
            : {}),
          src: geApiData.js,
          apiOpts: geApiOpts
        },
        styles: {
          header: geApiData.header.styleElements[0].props,
          ...(isFooterAvailable ? { footer: geApiData.footer.styleElements[0].props } : {})
        }
      }

      function enhanceApp(App: AppType) {
        return function WrappedApp(props: AppProps) {
          return (
            <GeContext.Provider value={{ value: globalElements.ctx }}>
              <App {...props} />
            </GeContext.Provider>
          )
        }
      }

      const originalRenderPage = ctx.renderPage

      ctx.renderPage = async (options = {}) => {
        const componentEnhancer = options as ComponentEnhancer
        return await originalRenderPage({
          ...componentEnhancer,
          enhanceApp: mergeEnhancers(componentEnhancer?.enhanceApp ?? ((a) => a), enhanceApp)
        })
      }
      const initialProps = (await BaseDocument.getInitialProps(ctx)) as ExtendedDocumentInitialProps
      const scripts = initialProps.scripts ?? { head: [], afterBody: [] }

      scripts.head.push(
        <GlobalElementsHead
          styles={globalElements.styles}
          hideFooter={globalElements.ctx?.apiOpts.hideFooter}
        />
      )
      scripts.afterBody.push(<GlobalElementsScript ctx={globalElements.ctx} />)

      return {
        ...initialProps,
        scripts,
        props: {
          html: {
            lang: globalElements.ctx?.apiOpts.lang
          }
        },
        globalElements
      }
    }
  }
}

export const GlobalElementsDocument = withGlobalElementsDoc(NextDocument)
