import type { SerializeOptions } from 'cookie'

const DEFAULT_MAX_AGE = 2600000

export default function getCookieOpts(cookieOpts: SerializeOptions = {}) {
  const { domain = '.telus.com', path = '/', maxAge = DEFAULT_MAX_AGE } = cookieOpts
  return {
    domain,
    path,
    maxAge,
    ...cookieOpts
  }
}
