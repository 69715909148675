import type { GlobalElementsApiOptions } from '../lib/types'
import { LOCALE_REGEX, PRESERVED_HASH_KEY, preservedCookieOpts } from '../lib/constants'
import { parse as cookieParse, serialize as cookieSerialize } from 'cookie'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import safeJsonParse from '../lib/safeJsonParse'
import getCookieOpts from '../lib/getCookieOpts'
import removePreservedHash from '../lib/removePreservedHash'

type GELocaleChangeEvent = CustomEvent<{
  language: string
  region: string
}>

export default function useGlobalElementsLocaleChange(apiOpts: GlobalElementsApiOptions) {
  const router = useRouter()
  const cookies = typeof document !== 'undefined' ? cookieParse(document.cookie) : {}
  const preservedHashObj = safeJsonParse(cookies[PRESERVED_HASH_KEY])

  useEffect(() => {
    function handleGELocaleChange(event: GELocaleChangeEvent) {
      const {
        detail: { language: lang, region: prov }
      } = event
      const cookieOpts = getCookieOpts(apiOpts.localeCookieOpts)

      // asPath excludes basePath and includes queries
      // remove hash from asPath, if it exists
      const pathWithQuery = router.asPath.replace(LOCALE_REGEX, '').split('#')[0]

      const { origin } = window.location

      const basePath = typeof apiOpts.basePath === 'string' ? apiOpts.basePath : router.basePath

      document.cookie = cookieSerialize('lang', lang, cookieOpts)
      document.cookie = cookieSerialize('prov', prov, cookieOpts)

      const redirectUrl = new URL(`${basePath}${pathWithQuery}`, origin).toString()

      window.location.assign(redirectUrl)
    }

    function saveUrlHash() {
      const { hash, pathname } = window.location
      const path = pathname.replace(LOCALE_REGEX, '')

      if (hash) {
        const serializedHashAndPath = JSON.stringify({ hash, path })
        document.cookie = cookieSerialize(
          PRESERVED_HASH_KEY,
          serializedHashAndPath,
          preservedCookieOpts
        )
      } else {
        // user is attempting to set a empty string as the new hash.
        removePreservedHash()
      }
    }

    window.addEventListener('GELocaleChange', handleGELocaleChange as EventListener)
    window.addEventListener('hashchange', saveUrlHash)
    window.addEventListener('beforeunload', saveUrlHash)
    return () => {
      window.removeEventListener('hashchange', saveUrlHash)
      window.removeEventListener('GELocaleChange', handleGELocaleChange as EventListener)
      window.removeEventListener('beforeunload', saveUrlHash)
    }
  }, [apiOpts.basePath, apiOpts.localeCookieOpts, router.asPath, router.basePath])

  if (preservedHashObj) {
    const { hash: preservedHash, path } = preservedHashObj
    const { pathname } = window.location
    const currentPath = pathname.replace(LOCALE_REGEX, '')

    if (currentPath === path) {
      // if a preserved hash exists on the active path then apply it.
      window.location.hash = preservedHash
    } else {
      // if a preserved hash exists and paths differ this means the user has changed pages.
      removePreservedHash()
    }
  }
}
