export default function safeJsonParse(data: string | undefined) {
  try {
    if (!data) {
      return null
    }

    return JSON.parse(data)
  } catch {
    return null
  }
}
