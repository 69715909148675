import type { PropsWithChildren } from 'react'
import Script from 'next/script'
import GlobalHeader from './GlobalHeader'
import GlobalFooter from './GlobalFooter'
import useGlobalElementsData from '../hooks/useGlobalElementsData'

import useGlobalElementsLocaleChange from '../hooks/useGlobalElementsLocaleChange'

const geCleanUpScript = "document.getElementById('__GE_DATA__').remove()"

interface GEProviderProps extends PropsWithChildren {
  header?: string
  footer?: string
  hideFooter?: boolean
}

function GlobalElementsProvider({ children, header, footer, hideFooter }: GEProviderProps) {
  const { data, isValidating } = useGlobalElementsData(header, footer, hideFooter)

  const {
    value: { header: geHeader, footer: geFooter, apiOpts, src }
  } = data

  useGlobalElementsLocaleChange(apiOpts)

  return (
    <>
      <GlobalHeader html={geHeader.html} />
      {children}
      {!apiOpts.hideFooter && <GlobalFooter html={geFooter?.html} />}
      {!isValidating && <Script src={src} type="text/javascript" />}
      <Script id="__GE_CLEAN_UP__" type="text/javascript">
        {geCleanUpScript}
      </Script>
    </>
  )
}

export default GlobalElementsProvider
