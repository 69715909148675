import { HttpClient } from '@telus/core-fetch'
import { type GlobalElementsApiOptions } from './types'

import { DEFAULT_API_PATH, DEFAULT_API_BASE_PROD, DEFAULT_API_BASE_STAGING } from './constants'
import { validateGeApiOpts } from './validateGeApiOpts'

const defaultApiBase =
  process.env.NEXT_PUBLIC_APP_ENV === 'production'
    ? DEFAULT_API_BASE_PROD
    : DEFAULT_API_BASE_STAGING

export const fetchGeData = async (geApiOpts: GlobalElementsApiOptions) => {
  const {
    header,
    footer,
    cookies,
    lang,
    prov,
    routeUrl,
    apiBase = defaultApiBase,
    apiBasePath = DEFAULT_API_PATH,
    mockData,
    hideFooter,
    hideNotifBanner,
    withoutFonts,
    hideCart
  } = geApiOpts

  const geClient = new HttpClient({
    baseUrl: apiBase
  })

  validateGeApiOpts(geApiOpts)

  if (mockData !== undefined) {
    return mockData
  }

  // Kyle T will push a new version of core-fetch to lossen the core-fetch types
  return await geClient.get(`${apiBasePath}/${lang}/${prov}`, {
    searchParams: {
      header,
      ...(!hideFooter ? { footer } : {}),
      ...(cookies ? { cookies: JSON.stringify(cookies) } : {}),
      routeUrl,
      'style-elements': true,
      'with-libs': true,
      hideNotifBanner,
      'without-fonts': withoutFonts,
      hideCart
    } as any
  })
}
