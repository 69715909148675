import type { GlobalElementsHeadProps } from '../lib/types'

/**
 * Global elements head component.
 *
 * @param props Props
 */
function GlobalElementsHead({ styles, hideFooter }: GlobalElementsHeadProps) {
  return (
    <>
      <style
        data-styled={styles.header['data-styled']}
        data-styled-version={styles.header['data-styled-version']}
        dangerouslySetInnerHTML={styles.header.dangerouslySetInnerHTML}
      />
      {!hideFooter && (
        <style
          data-styled={styles.footer?.['data-styled']}
          data-styled-version={styles.footer?.['data-styled-version']}
          dangerouslySetInnerHTML={styles.footer?.dangerouslySetInnerHTML}
        />
      )}
    </>
  )
}

export default GlobalElementsHead
