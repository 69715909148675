import serialize from 'serialize-javascript'
import type { ContextType } from '../lib/types'

interface GlobalElementsScriptProps {
  ctx: ContextType
}

function GlobalElementsScript({ ctx }: GlobalElementsScriptProps) {
  const isFooterAvailable = !ctx.apiOpts.hideFooter
  return (
    <script
      id="__GE_DATA__"
      dangerouslySetInnerHTML={{
        __html: `
        window.__GE_SRC = ${serialize(ctx.src)};
        window.__HEADER_HTML = ${serialize(ctx.header.html)};
        ${isFooterAvailable ? `window.__FOOTER_HTML = ${serialize(ctx.footer?.html)};` : ''} 
        window.__HEADER_CONTENT = ${serialize(ctx.header.content, { isJSON: true })};
        ${
          isFooterAvailable
            ? `window.__FOOTER_CONTENT = ${serialize(ctx.footer?.content, { isJSON: true })};`
            : ''
        }
        window.__GE_API_OPTS = ${serialize(ctx.apiOpts)};
        `
      }}
    />
  )
}

export default GlobalElementsScript
