// Dupe of file from next-locale-utilities because that package is ESM only and this one is CJS...
import { parse as cookieParse } from 'cookie'
import type { GetServerSidePropsContext } from 'next'
import type { DocumentContext } from 'next/document'

export interface ServerLocale {
  lang?: string
  prov?: string
  geDefaultedLang?: boolean
  geDefaultedProv?: boolean
}

export default function getServerSideLocale(
  ctx: GetServerSidePropsContext | DocumentContext
): ServerLocale {
  const setReqCookieHeader = ctx.req?.headers['set-cookie']
  const setResCookieHeader = ctx.res?.getHeader('Set-Cookie')

  // next 13 cookie parsing
  if (Array.isArray(setReqCookieHeader)) {
    // Next 13.5.4 ninja fixed their cookies. Now they are comma separated.
    // We should remove this check in the future. when all teams have migrated to >=13.5.4
    const cookies =
      setReqCookieHeader.length === 1 ? setReqCookieHeader[0].split(',') : setReqCookieHeader
    const serializedCookies = cookies.join(';')
    const {
      lang,
      prov,
      ge_defaultedLang: geDefaultedLang,
      ge_defaultedProv: geDefaultedProv
    } = cookieParse(serializedCookies)

    return {
      lang,
      prov,
      geDefaultedLang: geDefaultedLang === 'true',
      geDefaultedProv: geDefaultedProv === 'true'
    }
  }

  // next 12 cookie parsing
  if (Array.isArray(setResCookieHeader)) {
    const serializedCookies = setResCookieHeader.join(';')
    const {
      lang,
      prov,
      ge_defaultedLang: geDefaultedLang,
      ge_defaultedProv: geDefaultedProv
    } = cookieParse(serializedCookies)

    return {
      lang,
      prov,
      geDefaultedLang: geDefaultedLang === 'true',
      geDefaultedProv: geDefaultedProv === 'true'
    }
  }

  return {}
}
