import { useContext } from 'react'
import useSWR from 'swr'
import useLocale from '@telus/use-locale'
import { useCookies } from 'react-cookie'
import GeContext from '../components/Context'
import getGeCookies from '../lib/getGeCookies'
import { fetchGeDataWithCircuitBreaker } from '../lib/fetchGeDataWithCircuitBreaker'
import type { ContextType } from '../lib/types'

function useGlobalElementsData(header?: string, footer?: string, hideFooter?: boolean) {
  const [allCookies] = useCookies()
  const geCookies = getGeCookies(allCookies)

  const globalElementsCtx = useContext(GeContext)
  const [locale] = useLocale()

  if (!globalElementsCtx.value) {
    globalElementsCtx.value = {
      src: window.__GE_SRC,
      header: {
        html: window.__HEADER_HTML,
        content: window.__HEADER_CONTENT
      },
      footer: {
        html: window.__FOOTER_HTML,
        content: window.__FOOTER_CONTENT
      },
      apiOpts: window.__GE_API_OPTS!
    }
    delete window.__GE_SRC
    delete window.__HEADER_HTML
    delete window.__FOOTER_HTML
    delete window.__GE_API_OPTS
  }

  const geApiOpts = {
    ...globalElementsCtx.value.apiOpts,
    header: header ?? globalElementsCtx.value.apiOpts.header,
    footer: footer ?? globalElementsCtx.value.apiOpts.footer,
    hideFooter: hideFooter ?? globalElementsCtx.value.apiOpts.hideFooter,
    lang:
      locale.lang ||
      globalElementsCtx.value.apiOpts.lang ||
      globalElementsCtx.value.apiOpts.defaultLang,
    prov:
      locale.prov ||
      globalElementsCtx.value.apiOpts.prov ||
      globalElementsCtx.value.apiOpts.defaultRegion,
    cookies: Object.keys(geCookies).length ? geCookies : ''
  }

  const swrKey = `GE-${geApiOpts.lang}/${geApiOpts.prov}`
  const { data, isValidating } = useSWR(
    swrKey,
    async () => {
      // Kyle T will push a new version of core-fetch to lossen the core-fetch types
      const geApiData = (await fetchGeDataWithCircuitBreaker(geApiOpts)) as any

      return {
        value: {
          header: {
            html: geApiData.header.html,
            content: geApiData.header.content
          },
          ...(!geApiOpts.hideFooter
            ? {
                footer: {
                  html: geApiData.footer.html,
                  content: geApiData.footer.content
                }
              }
            : {}),
          src: geApiData.js,
          apiOpts: geApiOpts
        } satisfies ContextType
      }
    },
    {
      fallbackData: globalElementsCtx as any,
      onSuccess: (data) => {
        const { hideFooter } = geApiOpts
        window.__HEADER_CONTENT = data.value.header.content
        if (!hideFooter) {
          window.__FOOTER_CONTENT = data.value?.footer?.content
        }
      }
    }
  )

  return { data, isValidating }
}

export default useGlobalElementsData
